<template>
  <!--  style="height: 100vh;"-->
  <div class="wrapper">
    <template>
      <v-container style="margin-top: 3%;margin-bottom:7%;">
        <v-row>
          <v-col
            v-for="(room,i) in listRoomAvaible"
            :key="i"
            class="align-self-start"
            cols="12"
            lg="4"
            sm="6"
          >
            <v-card
              dense
              elevation="3"
            >
              <v-img
                :src="photos(room.room_photos)[0]"
                height="250"
              ></v-img>
              <v-card-title>
                {{ room.room_name }}
                <v-btn
                  color="success"
                  icon
                >
                  <v-icon>{{ icon[1] }}</v-icon>
                </v-btn>
              </v-card-title>
              <v-card-text>
                <p class="text--primary text-base">
                  {{ room.room_total_price }} FCFA/ Sejour
                </p>
                <p class="text--primary text-base">
                  Type : {{ room.room_type.room_type_name }}
                </p>
                cuisine-gaz-chauffage...
              </v-card-text>
              <v-card-actions class="primary pa-0">
                <v-btn
                  block
                  color="primary"
                  dark
                  large
                  @click="show(photos(room.room_photos))"
                >
                  Voir les images
                  <v-icon dark>
                    {{ icon[0] }}
                  </v-icon>
                </v-btn>
                <div
                  v-viewer
                  class="images"
                >
                  <viewer
                    v-show="false"
                    :images="photos(room.room_photos)"
                  >
                    <img
                      v-for="src in photos(room.room_photos)"
                      :key="src"
                      :src="src"
                    >
                  </viewer>
                </div>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </div>
</template>

<script>

import { mdiCheck, mdiEye } from '@mdi/js'

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Availability',
  data() {
    return {
      icon: [mdiEye, mdiCheck],
      index: null,
      listRoomAvaible: [],
    }
  },
  computed: {},
  mounted() {
    this.loadRoom()
  },
  methods: {
    loadRoom() {
      this.$axios.get(this.$endpoint.LoadRoomAvaible).then(rp => {
        this.listRoomAvaible = rp.data
      })
    },
    // eslint-disable-next-line camelcase
    show(room_images) {
      this.$viewerApi({
        images: room_images,
      })
    },
    photos(imgtable) {
      const finaltable = []
      const source = imgtable.split(';')
      source.forEach(src => {
        finaltable.push(`${process.env.VUE_APP_API_ASSET_URL}${src}`)
      })

      // console.log(finaltable)

      return finaltable
    },
  },
}
</script>

<style scoped>

</style>
